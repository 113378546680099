import revive_payload_client_8131GO1kW0 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vwzykz5hgvctojesswmgu6g3uy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mdAC27ZMKt from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vwzykz5hgvctojesswmgu6g3uy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5kZkOlklPD from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vwzykz5hgvctojesswmgu6g3uy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_uOZsSRysPH from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vwzykz5hgvctojesswmgu6g3uy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1Zpb7UPMoB from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vwzykz5hgvctojesswmgu6g3uy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DLRpapCT2x from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vwzykz5hgvctojesswmgu6g3uy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ccl2oi94b3 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vwzykz5hgvctojesswmgu6g3uy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZVpcc2TzG2 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_NXgrPwRAWs from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_vwzykz5hgvctojesswmgu6g3uy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_EbELCf3CCh from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0GX2wNXaPc from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import unocss_MzCDxu9LMj from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/unocss.mjs";
import axios_OijlQmfXsA from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/axios.ts";
import dompurify_client_EYdHkZatd0 from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/dompurify.client.ts";
import nprogress_lI8WDWlVpm from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/nprogress.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/sentry.client.ts";
export default [
  revive_payload_client_8131GO1kW0,
  unhead_mdAC27ZMKt,
  router_5kZkOlklPD,
  payload_client_uOZsSRysPH,
  navigation_repaint_client_1Zpb7UPMoB,
  check_outdated_build_client_DLRpapCT2x,
  chunk_reload_client_ccl2oi94b3,
  plugin_vue3_ZVpcc2TzG2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NXgrPwRAWs,
  switch_locale_path_ssr_EbELCf3CCh,
  i18n_0GX2wNXaPc,
  unocss_MzCDxu9LMj,
  axios_OijlQmfXsA,
  dompurify_client_EYdHkZatd0,
  nprogress_lI8WDWlVpm,
  sentry_client_KAXFuL2wum
]